<template>
    <div class="CompanyEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="company" label-width="80px" :rules="rules" size="small">
                <el-form-item label="企业名称" prop="name" :rules="rules.name">
                    <el-input v-model.trim="company.name" />
                </el-form-item>
                <el-form-item label="简称">
                    <el-input v-model.trim="company.shortName" />
                </el-form-item>
                <el-form-item label="公司电话" prop="telephone" :rules="rules.telephone">
                    <el-input v-model.trim="company.telephone" />
                </el-form-item>
                <el-form-item label="公司地址" prop="address" :rules="rules.address">
                    <el-input v-model.trim="company.address" />
                </el-form-item>
                <el-form-item label="公司介绍">
                    <el-input v-model.trim="company.introduce" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'CompanyEdit',
    props: ['form'],
    data() {
        return {
            company: {},
            url: '/system/company/patch_c/',
            rules: {
                name: [{ required: true, trigger: 'blur', message: '请输入企业名称' }],
                telephone: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '电话格式为11位数字，固定电话格式为区号+电话号，例：01011111111',
                        pattern: /\d{10,12}/,
                    },
                ],
                address: [{ required: true, trigger: 'blur', message: '请输入联系地址' }],
            },
        };
    },
    mounted() {
        this.company = {
            name: this.form.name,
            shortName: this.form.shortName,
            telephone: this.form.telephone,
            address: this.form.address,
            introduce: this.form.introduce,
        };
    },
    methods: {
        handleSave() {
            const company = this.company;
            if (!company.name) {
                this.$alert('请填写企业名称', '提示', { type: 'warning' });
                return;
            }
            if (!company.telephone) {
                this.$alert('请填写公司电话', '提示', { type: 'warning' });
                return;
            }
            if (!company.address) {
                this.$alert('请填写具体的公司地址', '提示', { type: 'warning' });
                return;
            }
            if (!company.introduce) {
                this.$alert('请填写公司介绍', '提示', { type: 'warning' });
                return;
            }
            UrlUtils.PatchRemote(this, this.url + this.form.code, this.company);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
